<template>
    <section class="section benefits-section for-customer">
        <section class="container has-text-centered">
            <p class="title">{{ t('meclee-ui.landings.main.why-choose-us.title') }}</p>
            <p class="subtitle">{{ t('meclee-ui.landings.main.why-choose-us.subtitle') }}</p>
            <div class="reasons">
                <template v-for="(itemInfo, index) in whyChooseUsContent" :key="itemInfo.key">
                    <div :class="[index % 2 !== 0 ? 'is-inverted' : '', 'reason-item']">
                        <div class="columns">
                            <div class="column is-6-desktop illustration">
                                <figure>
                                    <img src="/meclee-ui/images/why_choose_us_1.svg" v-if="itemInfo.img === 'why_choose_us_1'" loading="lazy" />
                                    <img src="/meclee-ui/images/why_choose_us_2.svg" v-if="itemInfo.img === 'why_choose_us_2'" loading="lazy" />
                                    <img src="/meclee-ui/images/illustration_main_2.svg" v-if="itemInfo.img === 'illustration_main_2'" loading="lazy" />
                                    <img src="/meclee-ui/images/illustration_main_3.svg" v-if="itemInfo.img === 'illustration_main_3'" loading="lazy" />
                                    <img src="/meclee-ui/images/illustration_main_4.svg" v-if="itemInfo.img === 'illustration_main_4'" loading="lazy" />
                                </figure>
                            </div>
                            <div class="column is-5-desktop is-offset-1-desktop content">
                                <div class="content-container">
                                    <p class="benefit-title">{{ t(itemInfo.contentTitle) }}</p>
                                    <p class="description" v-if="(typeof itemInfo.contentText === 'string')" v-html="t(itemInfo.contentText)"></p>
                                    <div class="description" v-else>
                                        <p v-if="itemInfo.contentText.text">{{ t(itemInfo.contentText.text) }}</p>
                                        <ul class="is-stylized">
                                            <li v-for="(item, index) in itemInfo.contentText.list" :key="index">{{ t(item)
                                            }}</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
            </div>
        </section>
    </section>
</template>
<script setup>
import { useLocalePath } from "#i18n";
const localePath = useLocalePath();
const { t } = useI18n();

const { whyChooseUsContent } = defineProps({
    whyChooseUsContent: Array
})


</script>
